<template>
  <div style="background-color: white;height: 100vh">
    <div class="box">
      <div
        style="position: absolute; width: 45px; height: 45px;"
        @click="slip()"
      >
        <img loading="lazy" src="../../assets/slipleft.png" />
      </div>

      <div class="ViewTitle">{{ title }}</div>
      <div class="contain gery">
        <el-form
          :label-position="labelPosition"
          label-width="120px"
          :model="form"
        >
          <p class="tips">
            <img loading="lazy" src="@/assets/tishi.png" alt="" srcset="" />
            <template v-if="hasOldPassword == 'true'">
              You have set a password, enter the old password to change the new password.
            </template>
            <template v-else> You have not set a password, enter a new password. </template>
          </p>
          <el-form-item label="Old password" v-if="hasOldPassword == 'true'">
            <el-input
              v-model="form.oldpassword"
              show-password
              placeholder="Please enter your old password"
            ></el-input>
          </el-form-item>
          <el-form-item label="New password">
            <el-input
              v-model="form.password"
              show-password
              placeholder="Please enter new password"
            ></el-input>
          </el-form-item>
          <el-form-item label="Confirm">
            <el-input
              v-model="form.requirePas"
              placeholder="enter password again"
              show-password
            ></el-input>
          </el-form-item>
          <div class="submit" @click="submit()">Confirm</div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { checkExist, pwdChange } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
// import BottomBanner from "@/components/BottomBanner.vue";
// import bottomMobile from "@/components/bottomMobile.vue";
export default {
  name: "ChangePhone",
  components: { },
  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      title: "Modify password",
      titleEn: "Modify password",
      labelPosition: "right",
      form: {
        oldpassword: "",
        password: "",
        requirePas: "",
      },
      hasOldPassword: "false",
      disabled: false,
      setCode: "Code",
      setCodeEn: "Verification code",
      languageSelect: "",
    };
  },
  computed: {},
  created() {
    this.checkExist();
    localStorage.setItem("myclick", 1);
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "Oil & Gas GPT",
      };
      shareUrl(shareData);
    }, 1000);
  },
  methods: {
    // 查询是否设置设置密码
    checkExist() {
      // console.log("查询是否设置设置密码");
      let data = {
        access_token: localStorage.getItem("Zyaccess_token"), // 竹云token
      };
      checkExist(data).then((res) => {
        console.log(res, "res");
        this.hasOldPassword = res.data;
      });
    },
    slip() {
      this.$router.go(-1);
    },

    submit() {
      if (this.hasOldPassword == "true" && !this.form.oldpassword) {
        this.$message.error("Please enter your old password");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("Please enter password");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("Please enter password again");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("Two passwords do not match");
        return false;
      }
      let data = {
        token: localStorage.getItem("Zyaccess_token"), // 竹云token
        password: this.form.password,
      };
      if (this.hasOldPassword == "true") {
        data.oldPassword = this.form.oldpassword;
      }
      pwdChange(data).then((res) => {
        if (!res.data) {
          this.$message({
            message: "Modified successfully！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              this.$router.go(-1);
            },
          });
        } else {
          let data1 = JSON.parse(res.data);
          this.$message({
            message: data1.error_msg,
            type: "error",
          });
        }
      });
      console.log(this.form);
    },

    submitEn() {
      if (this.hasOldPassword == "true" && !this.form.oldpassword) {
        this.$message.error("Please enter old password");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("Please enter password");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("Please enter your password again");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("Two passwords do not match");
        return false;
      }
      let data = {
        token: localStorage.getItem("Zyaccess_token"), // 竹云token
        password: this.form.password,
      };
      if (this.hasOldPassword == "true") {
        data.oldPassword = this.form.oldpassword;
      }
      pwdChange(data).then((res) => {
        if (!res.data) {
          this.$message({
            message: "Modified successfully！", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              this.$router.go(-1);
            },
          });
        } else {
          let data1 = JSON.parse(res.data);
          this.$message({
            message: data1.error_msg,
            type: "error",
          });
        }
      });
      console.log(this.form);
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  background: #fff;
  .ViewTitle {
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #ebebeb;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
    text-align: center;
  }
  .contain {
    padding:25px 20px 42px 20px;
    width: 100vw;
    position: relative;

    .tips {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      // margin-left: 40px;
      font-size: 14px;
      color: #666;
      line-height: 20px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .el-form-item {
      margin-bottom: 15px;
    }
    .submit {
      width: 320px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      border-radius: 5px;
      margin:30px auto 20px auto;
      background: var(--main-color);
      color: #ffffff;
      cursor: pointer;
    }
  }
  .codeBox {
    .setCode {
      position: absolute;
      right: 50vw;
      width: 110px;
      height: 48px;
      // line-height: 50px;
      text-align: center;
      border-radius: 4px;
      background: var(--main-color);
      color: #fff;
      font-size: 14px;
      margin-left: 15px;
    }
  }
  ::v-deep {
    .el-input__inner {
      width: 55vw;
      height: 48px;
      background-color: #fff;
    }

    .el-form-item__label {
      color: #666;
      font-size: 14px;
      padding-right: 25px;
      line-height: 48px;
      text-align: right;
    }
    .el-input__suffix{

      right:-30px;
    }
  }
}

::v-deep {
  .el-form-item__content {
    width: 200px !important;
  }
  .el-input__inner {
    height: 48px;
  }

  .el-form-item__label {
    color: #666;
    font-size: 16px;
    padding-right: 32px;
    line-height: 48px;
  }
  .codeBox {
    .el-form-item__content {
      .el-input {
        flex: 1;
      }
      .setCode {
        width: 75px;
        height: 48px;
        // line-height: 50px;
        text-align: center;
        border-radius: 4px;
        background: var(--main-color);
        color: #fff;
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }
}
</style>

